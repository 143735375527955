/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@adyen/adyen-web/dist/adyen.css";

.mat-hint {
    background-color: beige;
    padding: 3px;
    border-radius: 5px;
}

.btn-primary {
    background-color: #3f51b5;
    border-color: #3f51b5;
}
